<template>
  <div class="grid">
    <div class="col-fixed side p-3">
      <pv-button v-if="store.getters.admin" class="p-button-secondary w-full mb-3" :class="buttonClass('/label/')" @click="gotoLabel" label="ラベル管理" />
      <pv-button v-else class="p-button-secondary w-full mb-3" :class="buttonClass('/subject/')" @click="gotoSubject" label="音声データ管理" />
      <pv-button class="p-button-secondary w-full mb-3" :class="buttonClass('/user/')" @click="gotoUser" label="アカウント管理" />
      <pv-button v-if="store.getters.admin" class="p-button-secondary w-full" :class="buttonClass('/admin/')" @click="gotoAdmin" label="システム管理者" />
    </div>
    <div class="col p-3">
      <router-view></router-view>
    </div>
  </div>
</template>

<style>
.topbar {
  border-bottom: 1px solid #e9ecef;
}
.side {
  width: 250px;
}
</style>

<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    function gotoSubject() {
      router.push("/subject/");
    }

    function gotoUser() {
      router.push("/user/");
    }

    function gotoAdmin() {
      router.push("/admin/");
    }

    function gotoLabel() {
      router.push("/label/");
    }

    function buttonClass(path) {
      return route.path == path ? '' : 'p-button-text';
    }

    return { store, gotoSubject, gotoUser, gotoAdmin, gotoLabel, buttonClass };
  }
}
</script>